import { useState } from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import * as Yup from 'yup'
import { saveAs } from 'file-saver'
import { ExportRangePicker } from 'components/ExportRangePicker'

import { Button, InputField, PanelHeader, RightPanel } from '~components'
import useApi from '~api/api'

type ExportForm = {
  locale: string
  reportType: 'aggregate' | 'detailed' | 'ticketype'
  reportStartDate: Date
  reportEndDate: Date
}

export const ExportPage = () => {
  const { t, i18n } = useTranslation()
  const api = useApi()

  const [exporting, setExporting] = useState(false)

  const initialValues: ExportForm = {
    locale: i18n.language,
    reportStartDate: moment().toDate(),
    reportEndDate: moment().toDate(),
    reportType: 'aggregate',
  }

  return (
    <RightPanel className="w-128">
      <PanelHeader
        returnsTo="/"
        title={t('ExportPage.title', 'Report Export')}
        subtitle={<p className="font-semibold text-lg">{t('ExportPage.subtitle')}</p>}
      />
      <Formik
        initialValues={initialValues}
        validateOnChange
        validateOnBlur
        enableReinitialize
        validationSchema={Yup.object().shape({
          reportStartDate: Yup.date().required(
            t('ValidationMessages.requiredStartDate', 'Please select a start date'),
          ),
          reportEndDate: Yup.date()
            .test(
              'max-90-days',
              t(
                'ValidationMessages.max90Days',
                'End date must be within 90 days of the start date',
              ),
              function (reportEndDate) {
                const { reportStartDate } = this.parent
                if (reportStartDate && reportEndDate) {
                  const diffInTime = moment(reportEndDate).diff(moment(reportStartDate))
                  return diffInTime <= moment.duration(93, 'day').asMilliseconds()
                }
              },
            )
            .required(t('ValidationMessages.requiredEndDate', 'Please select an end date')),
        })}
        onSubmit={async (values) => {
          setExporting(true)
          const s2ab = (s: string) => {
            const buf = new ArrayBuffer(s.length)
            const view = new Uint8Array(buf)
            for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
            return buf
          }
          const response = await api.post<string>(`/stats/export/${values.reportType}/`, {
            ...values,
            reportStartDate: moment(values.reportStartDate).format('YYYY-MM-DD'),
            reportEndDate: moment(values.reportEndDate).format('YYYY-MM-DD'),
          })
          const data = response.data
          const url = window.URL.createObjectURL(
            new Blob([s2ab(atob(data))], {
              type: 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet',
            }),
          )
          let reportDates = moment(values.reportStartDate).format('YYYY-MM-DD')
          if (values.reportStartDate !== values.reportEndDate) {
            reportDates = `${moment(values.reportStartDate).format('YYYY-MM-DD')}_${moment(
              values.reportEndDate,
            ).format('YYYY-MM-DD')}`
          }
          saveAs(
            url,
            `${values.reportType}_${reportDates}_report_${moment.utc().format('YYYY_MM_DD')}.xlsx`,
          )
          setExporting(false)
        }}>
        {({ submitForm, isValid, values }) => {
          console.log('values', values)
          return (
            <>
              <div className="text-md font-semibold">
                {t('ExportPage.select_report_type', 'Select Report Type')}
              </div>
              <div className="grid grid-cols-2 gap-4 mb-1">
                <InputField
                  name="reportType"
                  type="radio"
                  disabled={exporting}
                  label={t('ExportPage.report_type', 'Report Type')}
                  selectValues={[
                    { id: 'aggregate', name: t('ExportPage.aggregate', 'Aggregate') },
                    { id: 'detailed', name: t('ExportPage.detailed', 'Detailed') },
                    { id: 'tickets', name: t('ExportPage.ticket_sales', 'Ticket Sales') },
                  ]}
                  className="py-2"
                />
              </div>
              <div className="flex flex-row mb-4">
                <div className="flex-initial shrink">
                  <ExportRangePicker
                    disabled={exporting}
                    startDateFieldName="reportStartDate"
                    endDateFieldName="reportEndDate"
                  />
                </div>
              </div>
              <Button
                onClick={submitForm}
                disabled={!isValid || exporting}
                className="w-48 h-10 text-md font-semibold">
                {t('ExportPage.export', 'Export')}
              </Button>
            </>
          )
        }}
      </Formik>
    </RightPanel>
  )
}
