import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { Button, InputField } from '~components'
import { CreateTicketTypeProps, TicketType, UpdateTicketTypeProps, VatRate } from '~types'
import useApi from '~api/api'

type InitialValues = CreateTicketTypeProps | UpdateTicketTypeProps

type TicketTypeFormProps = {
  initialValues: InitialValues
  submitLabel: string
  onSubmit: (values: InitialValues, actions: FormikHelpers<InitialValues>) => void
  loading?: boolean
}

export const TicketTypeForm: React.FC<TicketTypeFormProps> = ({
  initialValues,
  submitLabel,
  onSubmit,
  loading,
}: TicketTypeFormProps) => {
  const { t } = useTranslation()
  const api = useApi()
  const { categoryId } = useParams<{ categoryId: string }>()

  const vatRates = useQuery<VatRate[]>({
    queryKey: ['vatRates'],
    queryFn: async () => {
      const response = await api.get<VatRate[]>('/payments/vat-rates/')
      return response.data
    },
  })

  const {
    data: bundleTypes,
    isLoading: loadingListBundleTypes,
    error: listBundleTypesError,
  } = useQuery<TicketType[]>({
    queryKey: ['categories', categoryId, 'bundles'],
    queryFn: async () => {
      const response = await api.get<TicketType[]>(`/tickets/categories/${categoryId}/bundles/`)
      return response.data
    },
    enabled: categoryId !== undefined,
  })

  if (!vatRates || vatRates.isLoading || !bundleTypes || loadingListBundleTypes) {
    return null
  }

  if (listBundleTypesError) {
    throw listBundleTypesError
  }

  return (
    <Formik
      initialValues={initialValues as InitialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('ValidationMessages.required', 'Is required')),
        pricePer: Yup.number().when('isBundledOnly', {
          is: false,
          then: (schema) => schema.required(t('ValidationMessages.required', 'Is required')),
        }),
        vatRate: Yup.string().when('isBundledOnly', {
          is: false,
          then: (schema) => schema.required(t('ValidationMessages.required', 'Is required')),
        }),
        minQuantity: Yup.number().when('isBundledOnly', {
          is: false,
          then: (schema) => schema.required(t('ValidationMessages.required', 'Is required')),
        }),
      })}
      onSubmit={onSubmit}
      enableReinitialize>
      {({ values, dirty }) => {
        console.log('values', values)
        return (
          <Form className="flex flex-col">
            <InputField
              name="isActive"
              type="switch"
              label={
                values.isActive ? t('Fields.active', 'Active') : t('Fields.inactive', 'Inactive')
              }
              className="pb-2 self-end text-lg"
              invertedLabel
            />
            {!values.isBundledOnly && (
              <InputField
                name="isFavorite"
                type="switch"
                label={t('Fields.isFavorite', 'Is favorite')}
                description={t(
                  'FieldDescs.isFavorite',
                  "If selected, this ticket type will show up in the favorites category, as well as in it's own category.",
                )}
                className="pb-2 self-end text-lg"
                invertedLabel
              />
            )}
            <InputField name="name" type="text" label={t('Fields.name', 'Name')} className="pb-2" />
            <InputField
              name="description"
              type="text"
              label={t('Fields.description', 'Description')}
              className="pb-2"
            />
            {!values.isBundledOnly && (
              <>
                <InputField
                  name="minQuantity"
                  type="number"
                  label={t('Fields.minQuantity', 'Min Quantity')}
                  className="pb-2"
                />
                <InputField
                  name="pricePer"
                  type="number"
                  label={t('Fields.pricePer', 'Price per')}
                  className="pb-2"
                />
                <InputField
                  name="vatRate"
                  type="select"
                  label={t('Fields.vatRate', 'Vat Rate')}
                  className="pb-2"
                  selectValues={(vatRates.data || []).map((vr) => {
                    return {
                      id: vr.id,
                      name: vr.rate.toString(),
                    }
                  })}
                />
                <InputField
                  name="bundledTickets"
                  type="multi-select"
                  label={t('Fields.bundledTickets', 'Bundled Tickets')}
                  className="pb-2"
                  selectValues={bundleTypes}
                />
              </>
            )}

            <Button type="submit" className="px-4 py-2" loading={loading} disabled={!dirty}>
              {submitLabel} {t('General.ticketType', 'Ticket Type')}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}
