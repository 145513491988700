import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { Button, InputField } from '~components'
import { CreateTicketCategoryProps, EditTicketCategoryProps } from '~types'

type InitialValues = CreateTicketCategoryProps | EditTicketCategoryProps

type TicketCategoryFormProps = {
  initialValues: InitialValues
  submitText: string
  onSubmit: (values: InitialValues, actions: FormikHelpers<InitialValues>) => void
  loading?: boolean
}

export const TicketCategoryForm: React.FC<TicketCategoryFormProps> = ({
  initialValues,
  submitText,
  onSubmit,
  loading,
}: TicketCategoryFormProps) => {
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('ValidationMessages.required', 'Is required')),
        position: Yup.string().required(t('ValidationMessages.required', 'Is required')),
      })}
      onSubmit={onSubmit}
      enableReinitialize>
      {({ values, dirty }) => (
        <Form className="flex flex-col">
          <InputField
            name="isActive"
            type="switch"
            label={
              values.isActive ? t('Fields.active', 'Active') : t('Fields.inactive', 'Inactive')
            }
            className="pb-2 self-end text-lg"
            invertedLabel
          />
          <InputField name="name" type="text" label={t('Fields.name', 'Name')} className="pb-2" />
          <InputField
            name="description"
            type="text"
            label={t('Fields.description', 'Description')}
            className="pb-2"
          />
          <Button type="submit" className="pt-4" loading={loading} disabled={!dirty}>
            {submitText} {t('General.ticketCategory', 'Category')}
          </Button>
        </Form>
      )}
    </Formik>
  )
}
