import React from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { PanelHeader, RightPanel, TicketCategoryForm } from '~components'
import { CreateTicketCategoryProps, TicketCategory } from '~types'
import useApi from '~api/api'

export const TicketCategoryAddPage = () => {
  const { t } = useTranslation()
  const api = useApi()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { mutateAsync: addTicketCategory, isPending: loadingAddTicketCategory } = useMutation({
    mutationFn: async ({ ticketCategory }: { ticketCategory: CreateTicketCategoryProps }) => {
      const response = await api.post<TicketCategory>(`/tickets/categories/`, ticketCategory)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['categories'] })
      queryClient.invalidateQueries({ queryKey: ['categories_and_tickets'] })
      toast.success(
        t('CreateCategoryScreen.createdMessage', 'Ticket Category created successfully.'),
      )
      navigate('/ticket-categories')
    },
    onError: () => {
      toast.error(t('CreateCategoryScreen.createError', "Couldn't create Ticket Category."))
    },
  })

  return (
    <RightPanel>
      <PanelHeader
        returnsTo="/ticket-categories"
        title={t('CreateCategoryScreen.title', 'Create a new Ticket Category.')}
        subtitle={t('CreateCategoryScreen.subtitle', 'Set a new Ticket Category for your tickets.')}
      />
      <TicketCategoryForm
        submitText={t('General.create', 'Create')}
        initialValues={
          {
            name: '',
            description: '',
            position: 0,
            isActive: true,
            isVisible: true,
          } as CreateTicketCategoryProps
        }
        onSubmit={async (values, actions) => {
          await addTicketCategory(
            { ticketCategory: values },
            {
              onSuccess: () => actions.resetForm(),
            },
          )
        }}
        loading={loadingAddTicketCategory}
      />
    </RightPanel>
  )
}
