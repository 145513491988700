import React from 'react'
import { FieldHookConfig, useField } from 'formik'
import DatePicker, { registerLocale } from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { de, enGB, es, fr, it } from 'date-fns/locale'
import { min } from 'date-fns'

import { Button } from '~components'

import 'react-datepicker/dist/react-datepicker.css'

interface DatePickerFieldProps {
  disabled?: boolean
  startDateFieldName: string
  endDateFieldName: string
}

export const ExportRangePicker: React.FC<DatePickerFieldProps> = ({
  disabled,
  startDateFieldName,
  endDateFieldName,
}) => {
  const { t } = useTranslation()

  const locale = window.localStorage.getItem('locale') || 'en'
  registerLocale('en', enGB)
  registerLocale('fr', fr)
  registerLocale('de', de)
  registerLocale('it', it)
  registerLocale('es', es)

  const [fieldStartDate, fieldStartDateMeta, fieldStartDateHelper] = useField({
    name: startDateFieldName,
  } as FieldHookConfig<Date | null>)
  const [fieldEndDate, fieldEndDateMeta, fieldEndDateHelper] = useField({
    name: endDateFieldName,
  } as FieldHookConfig<Date | null>)

  console.log('fieldStartDate', fieldStartDate)
  console.log('fieldEndDate', fieldEndDate)

  const handleQuickPick = (start: Date, end: Date) => {
    fieldStartDateHelper.setValue(start)
    fieldEndDateHelper.setValue(end)
    fieldEndDateHelper.setTouched(true)
    fieldStartDateHelper.setTouched(true)
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-row py-2 justify-between">
        <div className="font-semibold">
          {t('ExportPage.select_export_timeframe', 'Select Export Timeframe')}
        </div>
        <div>
          {fieldStartDateMeta.touched && fieldStartDateMeta.error && (
            <div className="text-red-700 font-bold">{fieldStartDateMeta.error}</div>
          )}
          {fieldEndDateMeta.touched && fieldEndDateMeta.error && (
            <div className="text-red-700 font-bold">{fieldEndDateMeta.error}</div>
          )}
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <DatePicker
          locale={locale}
          className="text-sm font-bold rounded-lg"
          dateFormat={'dd/MM/yyyy'}
          isClearable
          onBlur={() => {
            fieldStartDateHelper.setTouched(true)
          }}
          selected={fieldStartDate.value}
          onChangeRaw={() => {
            fieldStartDateHelper.setTouched(true)
          }}
          onChange={(startDate: Date | null) => {
            fieldStartDateHelper.setValue(startDate)
          }}
          maxDate={new Date()}
          showDisabledMonthNavigation
          disabled={disabled || false}
        />
        <DatePicker
          locale={locale}
          className="ml-4 text-sm font-bold rounded-lg"
          dateFormat={'dd/MM/yyyy'}
          isClearable
          onBlur={() => {
            fieldEndDateHelper.setTouched(true)
          }}
          selected={fieldEndDate.value}
          onChangeRaw={() => {
            fieldEndDateHelper.setTouched(true)
          }}
          onChange={(date: Date | null) => {
            fieldEndDateHelper.setValue(date)
          }}
          minDate={min([new Date(), fieldStartDate.value || new Date()])}
          maxDate={min([
            new Date(),
            fieldStartDate.value
              ? moment(fieldStartDate.value).add(1, 'quarter').toDate()
              : new Date(),
          ])}
          showDisabledMonthNavigation
          disabled={disabled || false}
        />
      </div>
      <div className="flex flex-row mt-4 justify-between">
        <div className="text-md font-semibold ">
          {t('ExportPage.quick_select_dates', 'Quick Select Dates')}
        </div>
        <div className="flex flex-col justify-center gap-2">
          <Button
            style="none"
            className="h-8 w-32 rounded-full border-1 border-teal-800 hover:bg-teal-500"
            disabled={disabled || false}
            onClick={() => {
              handleQuickPick(moment().toDate(), moment().toDate())
            }}>
            {t('ExportPage.today', 'Today')}
          </Button>
          <Button
            style="none"
            className="h-8 w-32 rounded-full border-1 border-teal-800 hover:bg-teal-500"
            disabled={disabled || false}
            onClick={() => {
              handleQuickPick(moment().startOf('month').toDate(), moment().toDate())
            }}>
            {t('ExportPage.this_month', 'This Month')}
          </Button>
          <Button
            style="none"
            className="h-8 w-32 rounded-full border-1 border-teal-800 hover:bg-teal-500"
            disabled={disabled || false}
            onClick={() => {
              const lastMonth = moment().subtract(1, 'month')
              handleQuickPick(
                lastMonth.startOf('month').toDate(),
                lastMonth.endOf('month').toDate(),
              )
            }}>
            {t('ExportPage.last_month', 'Last Month')}
          </Button>
          <Button
            style="none"
            className="h-8 w-32 rounded-full border-1 border-teal-800 hover:bg-teal-500"
            disabled={disabled || false}
            onClick={() => {
              const thisQuarter = moment()
              handleQuickPick(thisQuarter.startOf('quarter').toDate(), moment().toDate())
            }}>
            {t('ExportPage.this_quarter', 'This Quarter')}
          </Button>
          <Button
            style="none"
            className="h-8 w-32 rounded-full border-1 border-teal-800 hover:bg-teal-500"
            disabled={disabled || false}
            onClick={() => {
              const lastQuarter = moment().subtract(1, 'quarter')
              handleQuickPick(
                lastQuarter.startOf('quarter').toDate(),
                lastQuarter.endOf('quarter').toDate(),
              )
            }}>
            {t('ExportPage.last_quarter', 'Last Quarter')}
          </Button>
        </div>
      </div>
    </div>
  )
}
