import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAlien,
  faAlien8bit,
  faBanana,
  faBinarySlash,
  faExclamationTriangle,
  faHatWizard,
  faMagnifyingGlass,
  faPersonWalking,
} from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'

interface Props {
  show: boolean
}
export const NoData: React.FC<Props> = ({ show }): React.ReactElement => {
  const { t } = useTranslation()

  if (!show) {
    return <></>
  }
  const alertCombos = [
    {
      icon: faAlien,
      text: t(
        'NoData.faAlien',
        'Looks like your data might have been abducted or there is no data to display!',
      ),
    },
    {
      icon: faExclamationTriangle,
      text: t(
        'NoData.faExclamationTriangle',
        "You ain't got no data to show! Time to make some...",
      ),
    },
    {
      icon: faAlien8bit,
      text: t(
        'NoData.faAlien8bit',
        'Since you have no data, this would have been a good spot for a mini-game.',
      ),
    },
    {
      icon: faBanana,
      text: t('NoData.faBanana', 'No data, have a banana instead. Enjoy!'),
    },
    {
      icon: faBinarySlash,
      text: t('NoData.faBinarySlash', 'No bits or bobs for that matter to show.'),
    },
    {
      icon: faMagnifyingGlass,
      text: t('NoData.faMagnifyingGlass', 'Even Sherlock could not find your data. There is none.'),
    },
    {
      icon: faHatWizard,
      text: t('NoData.faHatWizard', 'Our incantations did not produce any data for you to see.'),
    },
    {
      icon: faPersonWalking,
      text: t(
        'NoData.faPersonWalking',
        'When you come back from a nice walk, there could be some data.',
      ),
    },
  ]

  const selectedAlert = alertCombos[Math.floor(Math.random() * alertCombos.length)]
  return (
    <div className="h-60 w-full rounded-b-md border-2 border-dashed border-slate-300">
      <div className="flex h-full flex-col items-center justify-center">
        <FontAwesomeIcon icon={selectedAlert.icon} size="4x" className="text-slate-400" />
        <div className="p-8 text-center text-xl font-medium text-slate-400">
          {selectedAlert.text}
        </div>
      </div>
    </div>
  )
}
