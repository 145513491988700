import { FocusEventHandler } from 'react'
import clsx from 'clsx'
import { FieldHookConfig, useField } from 'formik'
import Select from 'react-select'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { useMemo } from 'react'

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
  }[Keys]

interface SelectValues {
  id: string | number
  uid: string
  name: string
}

type SelectValuesProp = RequireAtLeastOne<SelectValues, 'id' | 'uid'>

interface SharedProps {
  id?: string
  name: string
  value?: string
  label: string
  invertedLabel?: boolean
  className?: string
  disabled?: boolean
  onBlur?: () => void
  description?: string
  noErrors?: boolean
}

interface SelectProps extends SharedProps {
  type: 'multi-select' | 'select' | 'radio'
  selectValues: SelectValuesProp[]
  min?: never
  max?: never
}

interface InputProps extends SharedProps {
  type: 'text' | 'password' | 'email' | 'checkbox' | 'switch'
  selectValues?: never
  min?: never
  max?: never
}

interface NumberAndDateProps extends SharedProps {
  type: 'number' | 'date'
  min?: number | string
  max?: number | string
  selectValues?: never
}

type InputFieldProps = SelectProps | InputProps | NumberAndDateProps

const EmotionCacheProvider = ({ children }: { children: React.ReactNode }) => {
  const cache = useMemo(
    () =>
      createCache({
        key: 'with-tailwind',
        insertionPoint: document.querySelector('title')!,
      }),
    [],
  )

  return <CacheProvider value={cache}>{children}</CacheProvider>
}

export const InputField: React.FC<InputFieldProps> = (props: InputFieldProps) => {
  const {
    label,
    name,
    type,
    value,
    disabled,
    selectValues,
    className,
    onBlur,
    description,
    invertedLabel,
    min,
    max,
    id,
    noErrors = false,
  } = props
  const configType = type === 'switch' ? 'checkbox' : type
  const config: FieldHookConfig<any> = { name, type: configType } // eslint-disable-line @typescript-eslint/no-explicit-any
  if (value) {
    config.value = value
  }
  const [field, meta, helpers] = useField(config)

  const handleBlur: FocusEventHandler<HTMLSelectElement | HTMLInputElement> = (event) => {
    if (onBlur) {
      onBlur()
    } else {
      field.onBlur(event)
    }
  }

  const renderField = () => {
    switch (type) {
      case 'multi-select':
        return (
          <div className="relative">
            <EmotionCacheProvider>
              <Select
                {...field}
                placeholder={null}
                classNames={{
                  indicatorSeparator: () => 'hidden',
                  option: () => 'bg-white text-gray-900',
                  multiValueLabel: () =>
                    'bg-slate-600 text-white font-semibold rounded-l-lg h-8 px-4 text-lg justify-center items-center flex',
                  multiValueRemove: () =>
                    'bg-slate-600 hover:bg-teal-400 text-white hover:text-white rounded-r-md text-xl',
                  control: () => 'h-16 w-full rounded-lg items-end flex',
                  dropdownIndicator: () => 'text-gray-400',
                  clearIndicator: () => 'text-gray-400',
                }}
                options={selectValues}
                defaultValue={meta.value}
                isMulti
                getOptionLabel={(row) => row.name}
                getOptionValue={(row) => row.uid}
                onChange={(value) => {
                  console.log(value)
                  helpers.setValue(value)
                }}
              />
            </EmotionCacheProvider>
            <label
              className="absolute top-0 start-0 p-4 h-full truncate pointer-events-none transition ease-in-out duration-100 border border-transparent peer-disabled:opacity-50 peer-disabled:pointer-events-none
              text-xs
              -translate-y-2.5
              text-gray-500">
              {label}
              {meta.error && !noErrors ? ':' : null}{' '}
              {meta.error && !noErrors === true ? meta.error : null}
            </label>
          </div>
        )
      case 'select':
        return (
          <div className="relative">
            <select
              {...field}
              className={clsx(
                'peer p-4 pe-9 block w-full border-gray-200 border-2 rounded-lg text-sm focus:border-teal-500 focus:ring-teal-500 disabled:opacity-50 disabled:pointer-events-none focus:pt-6 focus:pb-2 [&:not(:placeholder-shown)]:pt-6 [&:not(:placeholder-shown)]:pb-2 autofill:pt-6 autofill:pb-2',
                meta.error &&
                  meta.touched &&
                  'focus:border-red-500 focus:border-t-transparent focus:outline-0 border-red-600 border-2 bg-red-50',
              )}
              onBlur={handleBlur}
              disabled={disabled}
              value={meta.value}>
              {!meta.value && <option value="">--- Select ---</option>}
              {selectValues?.map((item) => (
                <option key={item.name + item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
            <label
              className="absolute top-0 start-0 p-4 h-full truncate pointer-events-none transition ease-in-out duration-100 border border-transparent peer-disabled:opacity-50 peer-disabled:pointer-events-none
            peer-focus:text-xs
            peer-focus:-translate-y-1.5
            peer-focus:text-gray-500
            peer-[:not(:placeholder-shown)]:text-xs
            peer-[:not(:placeholder-shown)]:-translate-y-1.5
            peer-[:not(:placeholder-shown)]:text-gray-500">
              {label} {meta.error ? ':' : null} {meta.error}
            </label>
          </div>
        )
      case 'radio':
        return (
          <div className="flex gap-8">
            {selectValues?.map((item) => (
              <div key={item.name + item.id} className="inline-flex items-center">
                <input
                  {...field}
                  type="radio"
                  checked={item.id === meta.value}
                  value={item.id}
                  onChange={() => helpers.setValue(item.id)}
                  className={clsx(
                    'h-4 w-4 border-gray-300 text-teal-600 focus:ring-teal-600',
                    meta.error &&
                      meta.touched &&
                      'focus:border-2 focus:border-red-500 focus:border-t-transparent focus:outline-0 border-red-600 border-2 bg-red-50',
                  )}
                  placeholder=""
                  onBlur={handleBlur}
                  disabled={disabled}
                />
                <label
                  className={clsx(
                    'mt-px cursor-pointer select-none text-gray-700 pl-1 font-semibold',
                    meta.error && meta.touched && 'text-red-600 ',
                  )}>
                  {item.name}
                </label>
              </div>
            ))}
          </div>
        )
      case 'checkbox':
        return (
          <div className="relative flex items-start">
            <div className="flex items-center h-5 mt-1">
              <input
                className="border-gray-200 rounded text-teal-600 focus:ring-teal-500 disabled:opacity-50 disabled:pointer-events-none"
                {...field}
                type="checkbox"
                placeholder=" "
                onBlur={handleBlur}
                disabled={disabled}
              />
            </div>
            <label htmlFor="hs-checkbox-delete" className="ms-3">
              <span className="mt-px cursor-pointer select-none font-light text-gray-700">
                {label}
              </span>
              <span className="block text-sm text-gray-600">{description}</span>
            </label>
          </div>
        )
      case 'switch':
        return (
          <div className="flex items-center">
            {invertedLabel && <label className="text-sm text-gray-500 me-3">{label}</label>}
            <input
              {...field}
              onBlur={handleBlur}
              disabled={disabled}
              type="checkbox"
              className="relative w-[3.25rem] h-7 p-px bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-teal-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-teal-600 checked:border-teal-600 focus:checked:border-teal-600
              before:inline-block before:w-6 before:h-6 before:bg-white checked:before:bg-teal-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200"
            />
            {!invertedLabel && <label className="text-sm text-gray-500 ms-3">{label}</label>}
          </div>
        )
      case 'date':
        return (
          <div className="flex items-center">
            {!invertedLabel && <label className="text-sm text-gray-500 me-3">{label}</label>}
            <input
              {...field}
              onBlur={handleBlur}
              disabled={disabled}
              type="date"
              className="p-2"
              min={min}
              max={max}
            />
            {invertedLabel && <label className="text-sm text-gray-500 ms-3">{label}</label>}
          </div>
        )
      default:
        return (
          <div className="relative">
            <input
              id={id}
              type={type}
              {...field}
              className={clsx(
                'peer p-4 block w-full border-gray-200 border-2 rounded-lg text-sm placeholder:text-transparent focus:border-teal-500 focus:ring-teal-500 disabled:opacity-50 disabled:pointer-events-none focus:pt-6 focus:pb-2 [&:not(:placeholder-shown)]:pt-6 [&:not(:placeholder-shown)]:pb-2 autofill:pt-6 autofill:pb-2',
                type === 'number' && 'text-right no-number-spin',
                meta.error &&
                  meta.touched &&
                  'focus:border-2 focus:border-red-500 focus:border-t-transparent focus:outline-0 border-red-600 border-2 bg-red-50',
              )}
              onBlur={handleBlur}
              disabled={disabled}
            />
            <label
              className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent peer-disabled:opacity-50 peer-disabled:pointer-events-none
              peer-focus:text-xs
              peer-focus:-translate-y-1.5
              peer-focus:text-gray-500
              peer-[:not(:placeholder-shown)]:text-xs
              peer-[:not(:placeholder-shown)]:-translate-y-1.5
              peer-[:not(:placeholder-shown)]:text-gray-500">
              {label}
              {meta.error && !noErrors ? ':' : null}{' '}
              {meta.error && !noErrors === true ? meta.error : null}
            </label>
          </div>
        )
    }
  }

  return (
    <>
      <div className={className} title={meta.error}>
        {renderField()}
      </div>
    </>
  )
}
