import React from 'react'
import clsx from 'clsx'

interface Props {
  children: React.ReactNode
  className?: string
}

export const RightPanel: React.FC<Props> = ({ children, className }) => {
  return (
    <div className="fixed inset-0 overflow-hidden" role="dialog">
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0">
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <div
              className={clsx([
                'h-full p-8  flex flex-col shadow-xl w-224 border-2 bg-white animate-r-slide-in overflow-y-scroll',
                className,
              ])}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
