import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { PanelHeader, RightPanel, TicketTypeForm } from '~components'
import { BundleType, CreateTicketTypeProps, TicketType } from '~types'
import useApi from '~api/api'

export const TicketTypeAddPage = () => {
  const { t } = useTranslation()
  const { categoryId } = useParams<{ categoryId: string }>()
  const { state } = useLocation()
  const navigate = useNavigate()
  const api = useApi()
  const queryClient = useQueryClient()

  const {
    mutateAsync: addTicketType,
    isPending: loadingAddTicketType,
    reset: resetAddTicketType,
  } = useMutation({
    mutationFn: async ({ ticketType }: { ticketType: CreateTicketTypeProps }) => {
      const response = await api.post<TicketType>(
        `/tickets/categories/${categoryId}/tickets/`,
        ticketType,
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['categories', categoryId, 'tickets'] })
      queryClient.invalidateQueries({ queryKey: ['categories_and_tickets'] })
      toast.success(t('CreateTicketScreen.createdMessage', 'Ticket created!'))
      navigate(`/ticket-categories/${categoryId}/tickets`)
    },
    onError: () => toast.error(t('CreateTicketScreen.createError', "Couldn't create Ticket")),
  })

  const {
    mutateAsync: addBundleType,
    isPending: loadingAddBundleType,
    reset: resetAddBundleType,
  } = useMutation({
    mutationFn: async ({ ticketType }: { ticketType: CreateTicketTypeProps }) => {
      const response = await api.post<BundleType>(
        `/tickets/categories/${categoryId}/bundles/`,
        ticketType,
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['categories', categoryId, 'bundles'] })
      toast.success(t('CreateTicketScreen.createdMessage', 'Ticket created!'))
      navigate(`/ticket-categories/${categoryId}/tickets`, { state: { tab: 'bundles' } })
    },
    onError: () => toast.error(t('CreateTicketScreen.createError', "Couldn't create Ticket")),
  })

  if (categoryId === undefined) {
    throw Error('You must provide a ticket category id in the url.')
  }

  const initialValues: CreateTicketTypeProps = {
    isActive: true,
    name: '',
    pricePer: 0,
    vatRate: '',
    minQuantity: 1,
    bundledTickets: [],
    category: categoryId,
    isBundledOnly: (state && state.isBundledOnly) || false,
    isFavorite: false,
  }

  return (
    <RightPanel>
      <PanelHeader
        returnsTo={`/ticket-categories/${categoryId}/tickets`}
        title={t('CreateTicketScreen.title', 'Create a new Ticket')}
        subtitle={t('CreateTicketScreen.subtitle', 'Offer a new Ticket to your customers.')}
      />
      <TicketTypeForm
        submitLabel={t('General.create', 'Create')}
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          if (values.isBundledOnly) {
            await addBundleType(
              { ticketType: values },
              {
                onSuccess: () => {
                  actions.resetForm()
                  resetAddBundleType()
                },
              },
            )
          } else {
            await addTicketType(
              { ticketType: values },
              {
                onSuccess: () => {
                  actions.resetForm()
                  resetAddTicketType()
                },
              },
            )
          }
        }}
        loading={loadingAddTicketType || loadingAddBundleType}
      />
    </RightPanel>
  )
}
