import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { FormDisplay, PanelHeader, RightPanel, TicketCategoryForm } from '~components'
import { useRenderError } from '~hooks'
import { EditTicketCategoryProps, TicketCategory } from '~types'
import useApi from '~api/api'

export const TicketCategoryEditPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const api = useApi()
  const queryClient = useQueryClient()
  const { ticketCategoryId: categoryId } = useParams<{ ticketCategoryId: string }>()

  if (!categoryId) {
    throw Error('You must provide a ticket category id in the url.')
  }

  const { mutateAsync: editTicketCategory, isPending: updatingTicketCategory } = useMutation({
    mutationFn: async ({ ticketCategory }: { ticketCategory: EditTicketCategoryProps }) => {
      const response = await api.put<TicketCategory>(
        `/tickets/categories/${ticketCategory.id}/`,
        ticketCategory,
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['categories'] })
      queryClient.invalidateQueries({ queryKey: ['categories_and_tickets'] })
      toast.success(t('EditCategoryScreen.updatedMessage', 'Ticket Category updated successfully.'))
      navigate('/ticket-categories')
    },
    onError: () => {
      toast.error(t('EditCategoryScreen.updateError', "Couldn't update Ticket Category."))
    },
  })

  const {
    data: initialValues,
    isLoading: loadingTicketCategory,
    error: fetchTicketCategoryError,
  } = useQuery<TicketCategory>({
    queryKey: ['categories', categoryId],
    queryFn: async () => {
      const response = await api.get<TicketCategory>(`/tickets/categories/${categoryId}/`)
      return response.data
    },
  })

  const fetchError = useRenderError({
    error: fetchTicketCategoryError,
    defaultMessage: t('EditCategoryScreen.fetchError', "Couldn't get Ticket Category."),
  })

  return (
    <RightPanel>
      <PanelHeader
        returnsTo="/ticket-categories"
        title={t('EditCategoryScreen.title', 'Edit Ticket Category')}
        subtitle={
          <p>
            <Trans
              i18nKey={`EditCategoryScreen.subtitle`}
              values={{
                resource: initialValues?.name,
              }}
              components={{
                strong: <strong />,
              }}
            />
          </p>
        }
      />
      <FormDisplay isLoading={loadingTicketCategory} error={fetchError}>
        {initialValues && (
          <TicketCategoryForm
            submitText={t('General.update', 'Update')}
            initialValues={initialValues}
            onSubmit={async (values) => {
              await editTicketCategory({ ticketCategory: values as EditTicketCategoryProps })
            }}
            loading={updatingTicketCategory}
          />
        )}
      </FormDisplay>
    </RightPanel>
  )
}
